import React from 'react';
import './PromaEnterprise.css';
import PageTitle from '../Shared/PageTitle/PageTitle';
import dokan2 from '../../../src/images/dokan2.jpg';
import dokan5 from '../../../src/images/Dokan5.jpg';

const PromaEnterprise = () => {
    return (
        <div className='mb-5'>
            <PageTitle title='Proma Enterprise'></PageTitle>
            <div className='container research-container mt-5'>

                <h2 className='container text-start mt-5 mb-5 fw-bold'>Proma Enterprise</h2>
                <div className='container mb-5'>
                    <img className='dokan-img mb-5' src={dokan2} alt="" />
                    <img className='dokan-img mb-5' src={dokan5} alt="" />
                </div>
                <div className='container single-corn'>
                    <h5 className='text-start'>Licence no.: 829</h5>
                    <h5 className='text-start mb-3'> Address: Mallikpur, Sunamganj 3000, Bangladesh</h5>
                    <h5 className='text-start'><span className='text-manab'>Owner: </span><span className='text-name'>Anik Lal Ray</span> </h5>
                    <h5 className='text-start'>Distributor/Dealer of Fulkoli Food Products Limited and  Akij Food Products.</h5>
                    <h5 className='text-start'>Distributor/Dealer of Fulkoli Food Products Limited and  Akij Food and Beverage Limited.</h5>
                    <h5 className='text-start mb-3'>Business items: Biscuits, breads, cakes of Fulkoli Food Products Limited, and soft drinks, orange juice, lemon juice, mango juice, bottled water, mango bar, powdered milk, liquid milk etc. of Akij Food and Beverage Limited.</h5>
                    <h5 className='text-start'><span className='text-manab'>Business Management: </span><span className='text-name'>Apurba Anirban</span> </h5>
                    <h5 className='text-start'>Period: January 2009– December 2012.</h5>
                    <h5 className='text-start'>Responsibilities: Monitoring selling of biscuits, breads, cakes of Fulkoli Food Products and soft drinks,
                        orange juice, bottled water of Akij Food Products. Visiting markets, advising sales representatives for
                        their monthly sale, and help to recruit additional van driver and salesmen.</h5>

                    <h5 className='text-start'><span className='text-manab'>Sales Representative: </span><span className='text-name'>Gandib Dhari Roy</span></h5>
                    <h5 className='text-start'>Period: January 2013–December 2024.</h5>
                    <h5 className='text-start'>Responsibilities: To calculate daily and
                        monthly debit and credit, prepare loss and profit report, supervise food delivery staffs and their duties, etc.</h5>
                </div>
            </div>
        </div>
    );
};

export default PromaEnterprise;