import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import gari1 from '../../images/gari1.jpg';
import Dokan2 from '../../images/dokan2.jpg';
import Dokan3 from '../../images/dokan3.jpg';
import Dokan5 from '../../images/Dokan5.jpg';
import mdokan2 from '../../images/mdokan2.jpg';
import './Home/Slider.css';
const Slider = () => {
    return (
        <Carousel fade className='mt-3'>

            <Carousel.Item>
                <img className='slider-img1' src={Dokan2} text="Proma Enterprise" />
                <Carousel.Caption>
                    {/* <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className='slider-img1' src={gari1} text="Enterprise Groups's Cars" />
                <Carousel.Caption>
                    {/* <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className='slider-img1' src={Dokan5} text="Proma Enterprise" />
                <Carousel.Caption>
                    {/* <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className='slider-img1' src={Dokan3} text="Manab Enterprise" />
                <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className='slider-img1' src={mdokan2} text="Shova Enterprise" />
                <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default Slider;