import React from 'react';
import './Surja.css';
import Card from 'react-bootstrap/Card';
import surjasen from '../../../src/images/Surjasen.jpg';
import gandib from '../../../src/images/Gandib.jpg';
import proma from '../../../src/images/proma.jpg';
import PageTitle from '../Shared/PageTitle/PageTitle';

const Surja = () => {
    return (
        <div className='container'>
            <PageTitle title='Surja Business Solution'></PageTitle>
            <h2 className='container mt-5 text-start fw-bold'>Surja Business Solution</h2>
            <div className='container mb-5 mt-3 staff-container'>

                <div className=' single-staff'>
                    <div>
                        <img className='staff-img' src={surjasen} alt="" />
                    </div>
                    <div>
                        <h3>Surjasen Roy</h3>
                        <h6 className='staff-designation'>Financial Accountant</h6>
                        {/* <p className='staff-text'>Bachelor of commerce with Honours in Accounting, National University, Bangladesh.
                            Accounting related experiences in running family business.
                        </p> */}
                        {/* <p className='staff-text'>
                            Accounting related experiences in running small and start-up businesses.
                        </p> */}
                        <p className='staff-text'>
                            Business related data collection, analysis and Marketing.
                        </p>
                    </div>
                </div>



                {/* <Card className='single-staff'>
                    <Card.Img className='w-50' variant="top" src={people1} />
                    <Card.Body>
                        <Card.Title>Gandib Dhari Roy</Card.Title>
                        <Card.Text>
                            This card has supporting text below as a natural lead-in to
                            additional content.{' '}
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted"><button className='btn btn-primary people-btn'>Detail</button></small>
                    </Card.Footer>
                </Card> */}
            </div>
        </div>
    );
};

export default Surja;