import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-container pt-4'>
            <div className='text-white fw-bold fs-5'>
                <p>Enterprise Groups</p>
                {/* <p>Trade Licence number dewa jabe</p>
                <p>Address</p> */}
            </div>
            <p className='text-white'>&copy; {new Date().getFullYear()} Copyright: Enterprise Groups</p>
        </div>
    );
};

export default Footer;