import React from 'react';
import './GandibIt.css';
import PageTitle from '../Shared/PageTitle/PageTitle';
import web1mocup from '../../images/web1mocup.png';

const GandibIt = () => {
    return (
        // <div className='container mt-5 mb-5 gandib-container'>
        //     <PageTitle title='Gandib It'></PageTitle>
        //     <div>
        //         <h2 className='container text-start mt-5 mb-5 fw-bold'>Gandib It</h2>
        //         <h5 className='text-start'>Gandib It is a digital platform that solve problems related to small businesses.</h5>
        //     </div>
        // </div>
        <div className='container mt-5 mb-5 gandib-container'>
            <PageTitle title='Gandib It'></PageTitle>
            <h1 className='container text-start mt-5 mb-5'>Gandib It is a digital platform that solve problems related to small businesses.</h1>

            <div className='mt-10'>
                <h1 className='flex justify-start p-5 font-bold fs-1'><span><a className='pr-2 link-primary' href='https://redonionbygandib.netlify.app/'>Red Onion</a></span> Website Demo</h1>
                <img className='gandib-img-mocup' src={web1mocup} alt="" />
            </div>

            <p className='mt-5 fs-2'><span className='fw-bold text-color'>Our Best Live Projects Link: </span>
                <p className='font-bold mb-2'><a className='link-color2' href='https://sweetcorninnovation.com/'>SweetcornResearch and Innovation Centre</a></p>
                <p className='font-bold mb-2'><a className='link-color2' href='https://bd-bike-parts-factory.web.app/'>BD Bike Parts Factory</a></p>
                <p className='font-bold mb-2'><a className='link-color2' href='https://car-inventory-bfad1.web.app/'>Car Inventory</a></p>
                <p className='font-bold mb-2'><a className='link-color2' href='https://redonionbygandib.netlify.app/'>Red Onion</a></p>
                <p className='font-bold'><a className='link-color2' href='https://gyanangkur-wedding-photography.web.app/'>Gyanangkur Wedding Photography</a></p>
            </p>

            <p className='mt-5 mb-5 fs-2'><span className='fw-bold text-color'>Our App Link: </span>
                <p className='font-bold mb-2'><a className='link-color2' href='https://play.google.com/store/apps/details?id=com.gandib.nupoetry&pli=1'>NU BD English Poetry 1st to 4t</a></p>
                <p className='font-bold'><a className='link-color2' href='https://play.google.com/store/apps/details?id=com.gandib.prepositionplus'>Preposition (A.P) and Tense+ En to Bn</a></p>
            </p>

            <div>
                <p className='mt-5 fs-2'><span className='fw-bold text-color'>Technologies : </span>
                    <div className='fs-4 p-2'>
                        <p className='text-color2 fw-semibold'>HTML</p>
                        <p className='text-color2 fw-semibold'>CSS</p>
                        <p className='text-color2 fw-semibold'>BOOTSTRAP</p>
                        <p className='text-color2 fw-semibold'>TAILWIND</p>
                        <p className='text-color2 fw-semibold'>JAVASCRIPT</p>
                        <p className='text-color2 fw-semibold'>REACT JS</p>
                        <p className='text-color2 fw-semibold'>NEXT JS</p>
                        <p className='text-color2 fw-semibold'>REACT NATIVE</p>
                        <p className='text-color2 fw-semibold'>MONGODB</p>
                        <p className='text-color2 fw-semibold'>MONGOOSE</p>
                    </div>
                </p>
            </div>
        </div>
    );
};

export default GandibIt;