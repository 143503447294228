import React from 'react';
import HomeIntro from '../../HomeIntro/HomeIntro';
import History from '../../History/History';
import People from '../../People/People';
import Products from '../../ManabEnterprise/ManabEnterprise';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import './Home.css';
import { useTypewriter } from 'react-simple-typewriter'
import Colaboration from '../../Colaboration/Colaboration';
import Slider from '../Slider';

const Home = () => {
    const { text } = useTypewriter({
        words: [`Enterprise Groups`],
        loop: 0, // Infinit
    })

    return (
        <div className='home-container'>
            <PageTitle title='Home'></PageTitle>
            <div class='heading'>
                {text}
            </div>
            <Slider></Slider>
            <HomeIntro></HomeIntro>
            {/* <History></History> */}
            {/* <People></People> */}
            {/* <SupportingStaff></SupportingStaff> */}
            {/* <Colaboration /> */}
        </div>
    );
};

export default Home;