import React from 'react';
import './Contact.css';
import PageTitle from '../Shared/PageTitle/PageTitle';

const Contact = () => {
    return (
        <div className='contact-container mt-5 mb-5 h-[100vh]'>
            <PageTitle title='Contact'></PageTitle>
            <div>
                <h5>Contact us: <button className='contact-btn' onClick={() => window.location.href = "mailto:it.gandib@gmail.com"} >it.gandib@gmail.com</button></h5>
            </div>
        </div>
    );
};

export default Contact;