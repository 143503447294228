import React from 'react';
import PageTitle from '../Shared/PageTitle/PageTitle';

const Login = () => {
    return (
        <div>
            <PageTitle title='Login'></PageTitle>
            <h2>Login</h2>
        </div>
    );
};

export default Login;