import './App.css';
import Header from './Pages/Shared/Header/Header';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home/Home';
import ManabEnterprise from './Pages/ManabEnterprise/ManabEnterprise';
import PromaEnterprise from './Pages/PromaEnterprise/PromaEnterprise';
import ShovaEnterprise from './Pages/ShovaEnterprise/ShovaEnterprise';
import GandibIt from './Pages/GandibIt/GandibIt';
import People from './Pages/People/People';
import Contact from './Pages/Contact/Contact';
import NotFound from './Pages/Shared/NotFound/NotFound';
import Login from './Pages/Login/Login';
import Footer from './Pages/Shared/Footer/Footer';
import HistoryDetail from './Pages/HistoryDetail/HistoryDetail';
import Surja from './Pages/Surja/Surja';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/home' element={<Home></Home>}></Route>
        <Route path='/manab-enterprise' element={<ManabEnterprise></ManabEnterprise>}></Route>
        <Route path='/proma-enterprise' element={<PromaEnterprise></PromaEnterprise>}></Route>
        <Route path='/shova-enterprise' element={<ShovaEnterprise></ShovaEnterprise>}></Route>
        <Route path='/gandib-it' element={<GandibIt></GandibIt>}></Route>
        <Route path='/surja-business-solution' element={<Surja></Surja>}></Route>
        <Route path='/history' element={<HistoryDetail></HistoryDetail>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/login' element={<Login></Login>}></Route>
        <Route path='*' element={<NotFound></NotFound>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
