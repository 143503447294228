import React from 'react';
import './HomeIntro.css';
import purpleCorn from '../../../src/images/purple.jpeg';
import yellowCorn from '../../../src/images/yellow2.JPEG';

const HomeIntro = () => {
    return (
        <div>
            {/* <h2 className='container text-start mt-5 mb-2 fw-bold'>Enterprise Groups</h2> */}
            <div className='container corn-container'>
                <div className=' single-corn'>
                    {/* <div>
                        <img className='corn-img' src={purpleCorn} alt="" />
                    </div> */}
                    <div>
                        {/* <h3>Purple Corn</h3> */}
                        <p className='corn-text fw-bold fs-5'>Enterprise Groups is a business platform for three consecutive businesses namely Manab Enterprise, Proma Enterprise and Shova Enterprise.</p>
                        {/* <button className='btn btn-primary people-btn'>Detail</button> */}
                    </div>
                </div>
                {/* 
                <div className=' single-corn'>
                    <div>
                        <img className='corn-img' src={yellowCorn} alt="" />
                    </div>
                    <div>
                        <h3>Yellow Sweetcorn</h3>
                        <p className='corn-text'>Yellow sweetcorn in the market is mainly based on the shrunken2 gene. The reason is that sweetness
                            enabled by the shrunken2 sweetcorn is more than two-fold that of the sugary1 and sugary enhancer
                            sweetcorn, and eight-fold than of starchy corn. It has a special nutrition called ‘carotenoid’ which
                            prevents age related macular eye degeneration.</p>
                        {/* <button className='btn btn-primary people-btn'>Detail</button> */}
                {/* </div> */}
                {/* </div> */}

            </div>
        </div>
    );
};

export default HomeIntro;