import React from 'react';
import PageTitle from '../Shared/PageTitle/PageTitle';
import './ShovaEnterprise.css';
import mdokan1 from '../../images/mdokan1.jpg';
import mdokan2 from '../../images/mdokan2.jpg';
import mdokan3 from '../../images/mdokan3.jpg';

const ShovaEnterprise = () => {
    return (
        <div className='mb-5'>
            <PageTitle title='Shova Enterprise'></PageTitle>
            <div className='container mt-5 health-container'>
                <h2 className='container text-start mt-5 mb-5 fw-bold'>Shova Enterprise</h2>
                <div>
                    <img className='dokan-img mb-5' src={mdokan2} alt="" />
                    <img className='dokan-img mb-5' src={mdokan1} alt="" />
                    <img className='dokan-img mb-5' src={mdokan3} alt="" />
                </div>

                <div className='single-corn'>
                    <h5 className='text-start'>Licence no.: 1368</h5>
                    <h5 className='text-start mb-3'> Address: Station Road, Sunamganj 3000, Bangladesh</h5>
                    <h5 className='text-start'><span className='text-manab'>Owner: </span><span className='text-name'>Manabendra Roy</span> </h5>
                    <h5 className='text-start'>Distributor/Dealer of Peoria Food Products Limited and Partex Beverage Limited.</h5>
                    <h5 className='text-start mb-3'>Business items: Biscuits, breads, cakes of Peuria Food Products Limited, and soft drinks, orange juice, bottled water of Partex Beverage Limited.</h5>
                    <h5 className='text-start'><span className='text-manab'>Business Advisor: </span><span className='text-name'>Apurba Anirban</span> </h5>
                    <h5 className='text-start'>Period: January 2013– August 2023.</h5>
                    <h5 className='text-start'>Responsibilities: Providing advice on value added tax (VAT), income tax, increasing revenue, and
                        recruitment of staff.</h5>

                    <h5 className='text-start'><span className='text-manab'>Sales and System Officer: </span><span className='text-name'>Gandib Dhari Roy</span> </h5>
                    <h5 className='text-start'>Period: January 2023– Till now.</h5>
                    <h5 className='text-start'>Responsibilities: Website development & maintenance, operation & maintenance of
                        official business system to wholesale selling of products and marketing of products etc.</h5>
                </div>
            </div>
        </div>
    );
};

export default ShovaEnterprise;