import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import CustomLink from '../CustomLink/CustomLink';
import logo from '../../../images/eglogo.png';

const Header = () => {

    return (
        <>
            <Navbar collapseOnSelect expand="lg" sticky='top' bg="primary" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img src={logo} height='30' alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={CustomLink} to="/home">Home</Nav.Link>
                            <Nav.Link as={CustomLink} to="/manab-enterprise">Manab Enterprise</Nav.Link>
                            <Nav.Link as={CustomLink} to="/proma-enterprise">Proma Enterprise</Nav.Link>
                            <Nav.Link as={CustomLink} to="/shova-enterprise">Shova Enterprise</Nav.Link>
                            <Nav.Link as={CustomLink} to="/gandib-it">Gandib It</Nav.Link>
                            <Nav.Link as={CustomLink} to="/surja-business-solution">Surja Business Solution</Nav.Link>

                        </Nav>
                        <Nav>
                            {/* <NavDropdown className='fw-bold' title="People" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="home#apurba">Dr Apurba Anirban</NavDropdown.Item> */}
                            {/* <NavDropdown.Item href="home#jutish">
                                    Jutish Das
                                </NavDropdown.Item>
                                <NavDropdown.Item href="home#rita">
                                    Rita Halder
                                </NavDropdown.Item> */}
                            {/* <NavDropdown.Item href="home#suvra">
                                    Suvra Shushmita
                                </NavDropdown.Item>
                            </NavDropdown> */}
                            <Nav.Link as={CustomLink} to="/contact">Contact</Nav.Link>

                            {/* <Nav.Link as={CustomLink} to="/login">
                                Login
                            </Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;