import React from 'react';
import './HistoryDetail.css';

const HistoryDetail = () => {
    return (
        <div>
            <h2 className='container text-start mt-5 fw-bold'>History</h2>
            <div className='container history-container'>
                <p>Corn (Zea mays ssp. mays), also known as maize, is a cereal crop. After rice and wheat, corn is the
                    third most cultivated crop of the world. About 9,000 years ago, modern corn (Zea mays ssp. mays)
                    was originated from a very close relative Zea mays ssp. parviglumis (an annual grass that is native to
                    south-western Mexico) (Matsuoka et al., 2002) . Corn has been domesticated for approximately
                    10,000 years (Beadle, 1980) under selection and continues its evolution.</p>
                <p>For thousands of years, corn was spread throughout North and South America (Mir et al., 2013). The
                    Spanish has introduced corn into the Europe in the 15 th century. In the 16 th century, the Portuguese
                    has taken it to Asia (Desjardin and McCarthy, 2004).</p>
                <p> Purple, blue, red etc. (coloured) corn was originated from teosinte, which is a colourless progenitor.
                    Regulatory genes played a vital role in this process, as well as human selection (Hanson et al., 1996).</p>
                <p> The discovery of sweetcorn is latest. Sugary sweetcorn was discovered by East and Hayes in 1911
                    and brittle sweetcorn was discovered by Cameron and Teas in 1953 (Feng et al., 2008).</p>
                <p>Super-sweetcorn shrunken2 was discovered by Professor R. A. Emerson from the Nebraska Ponka
                    Indian reservation in 1914 (Hutchison, 1921). Commercial hybrids of the widely used shrunken2
                    super-sweetcorn named ‘Golden Cross’ and ‘Iochief hybrids’ was developed by J. R. Laughnan
                    (Laughnan, 1961).</p>
                <p>Now a days, ‘Siam Ruby Queen’ super-sweetcorn with red colour is found in the market. Purple
                    sweetcorn, however, has much more anthocyanin than red sweetcorn.</p>
            </div>
            <h5 className='container text-start mt-5'>References</h5>
            <div className='container reference-container mb-5'>
                <p>Beadle, G. W. (1980). The Ancestry of Corn. Scientific American, 242(1), 112.
                    doi:10.1038/scientificamerican0180-112</p>

                <p>Desjardin, A., &amp; McCarthy, S. ( 2004). Miho-makka-and-yu-mai: Early journeys of Zea mayz to Asia.
                    USA: US Department of Agriculture</p>
                <p>  Feng, Z., Liu, J., Fu, F., &amp; Li, W. (2008). Molecular Mechanism of Sweet and Waxy in Maize.
                    International Journal of Plant Breeding and Genetics, 2(2), 93-100.
                    doi:10.3923/ijpbg.2008.93.100</p>
                <p>Hanson, M. A., Gaut, B. S., Stec, A. O., Fuerstenberg, S. I., Goodman, M. M., Coe, E. H., &amp; Doebley,
                    J. F. (1996). Evolution of anthocyanin biosynthesis in maize kernels: the role of regulatory and
                    enzymatic loci. Genetics, 143(3), 1395-1407.</p>
                <p>Hutchison, C. B. (1921). Heritable characters of maize VII. Shrunken endosperm. Journal of Heredity,
                    12(2), 76-83. doi:10.1093/oxfordjournals.jhered.a102061</p>
                <p>Laughnan, J. R. (1961). Super sweet, a product of mutation breeding in corn. Seed World, 13, 18-19.</p>
                <p>Matsuoka, Y., Vigouroux, Y., Goodman, M. M., Jesus Sanchez, G., Buckler, E., &amp; Doebley, J. (2002).
                    A single domestication for maize shown by multilocus microsatellite genotyping. Proceedings
                    of the National Academy of Sciences of the United States of America, 99(9), 6080.
                    doi:10.1073/pnas.052125199</p>
                <p> Mir, C., Zerjal, T., Combes, V., Dumas, F., Madur, D., Bedoya, C., . . . Charcosset, A. (2013). Out of
                    America: tracing the genetic footprints of the global diffusion of maize. Theor Appl Genet,
                    126(11), 2671-2682. doi:10.1007/s00122-013-2164-z</p>
            </div>
        </div>
    );
};

export default HistoryDetail;