import React from 'react';
import './ManabEnterprise.css';
import PageTitle from '../Shared/PageTitle/PageTitle';
import Dokan3 from '../../images/dokan3.jpg';
import Baba1 from '../../images/baba1.jpg';

const ManabEnterprise = () => {
    return (
        <div className='mb-5'>
            <PageTitle title='Manab Enterprise'></PageTitle>
            <div className='container product-container mt-5'>
                <h2 className='container text-start mt-5 mb-5 fw-bold'>Manab Enterprise</h2>
                <div className='container mb-5'>
                    <img className='dokan-img' src={Baba1} alt="" />
                    <img className='dokan-img' src={Dokan3} alt="" />
                </div>
                <div className='container single-corn'>
                    <h5 className='text-start'>Licence no.: 2100</h5>
                    <h5 className='text-start mb-3'> Address: Mallikpur, Sunamganj 3000, Bangladesh</h5>
                    <h5 className='text-start'><span className='text-manab'>Owner: </span> <span className='text-name'>Manik Lal Ray</span></h5>
                    <h5 className='text-start'>Distributor/Dealer of Fulkoli Food Products Limited and Sajeeb Corporation.</h5>
                    <h5 className='text-start mb-3'>Business items: Biscuits, breads, and cakes of Fulkoli Food Products Limited; Juices, nodles and pickles etc. of Sajeeb Corporation.</h5>
                    <h5 className='text-start'><span className='text-manab'>Sales and Marketing: </span><span className='text-name'>Apurba Anirban</span></h5>
                    <h5 className='text-start'>Period: January 2001–December 2008.</h5>
                    <h5 className='text-start'>Responsibilities: Supplying orders of food items, distribution of the items with delivery man and pickup
                        van, keeping records of selling and purchasing of products and help to recruit van driver and salesmen.</h5>

                    <h5 className='text-start'><span className='text-manab'>Sales Executive: </span><span className='text-name'>Gandib Dhari Roy</span></h5>
                    <h5 className='text-start'>Period: January 2019–December 2022.</h5>
                    <h5 className='text-start'>Responsibilities: As a distributor of Fulkoli Food Products Ltd, Bangladesh, responsibilities were to calculate daily and
                        monthly debit and credit, prepare loss and profit report, supervise food delivery staffs and their duties, etc.</h5>
                </div>
            </div>
        </div>
    );
};

export default ManabEnterprise;